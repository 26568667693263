<template>
  <component :is="'b-card'">

    <!-- Alert: No item found -->
    <b-tabs pills>

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">SelfPay profile</span>
        </template>
        <profile-selfpaids class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: profile CA -->
      <b-tab v-if="$store.getters['Users/usersData'].corporate_account !== null">
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Corporate account information</span>
        </template>
        <profile-corporate-account-self class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Bookings -->
      <b-tab v-if="$store.getters['Users/usersData'].booking">
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Bookings information</span>
        </template>
        <selfpaids-bookings class="mt-2 pt-75" v-if="$store.getters['Users/usersData'].booking.length !== 0" />
        <div style=" display: flex;align-items: center;justify-content: center;">
          <h3 style="color: red;margin-top: 30px;padding: 20px; background: #f6f6f6"
            v-if="$store.getters['Users/usersData'].booking.length == 0">
            There are no bookings
          </h3>
        </div>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Payment Method</span>
        </template>
        <template v-if="dataSelfpaids != null">
          <template v-for="payment in dataSelfpaids.payment_methods">
            <FormSelfpaidPaymentMethod v-if="renderCompont"
            v-on:deletePaymentMethod="deletePaymentMethod($event)"
             v-on:updateDefaultPaymentMethod="updateDefaultPaymentMethod($event)"
              v-on:updatePaymentMethod="updatePaymentMethod($event)"
              v-on:addEmptyPaymentMethod="addEmptyPaymentMethod()" :userData="dataSelfpaids"
              :isDefault="payment.is_default" :paymentMethodId="payment.stripe_payment_method_id" :clientId="clientId">
            </FormSelfpaidPaymentMethod>
          </template>
        </template>


      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import FormSelfpaidPaymentMethod from '@/@core/components/user-details-selfpaids/SelfpaidPaymentMethod.vue'
import ProfileSelfpaids from "@core/components/user-details-selfpaids/ProfileSelfpaids";
import ProfileCorporateAccountSelf from "@core/components/user-details-selfpaids/ProfileCorporateAccountSelf";
import SelfpaidsBookings from "@core/components/user-details-selfpaids/SelfpaidsBookings";
import { getMessageError } from "@core/utils/utils";

export default {
  name: "DetailsSelfpaid",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    FormSelfpaidPaymentMethod,
    ProfileSelfpaids,
    ProfileCorporateAccountSelf,
    SelfpaidsBookings

  },
  data() {
    return {
      info: {},
      dataSelfpaids: null,
      clientId: '',
      renderCompont:true,
    }
  },
  methods: {
    async getInfoSelfpaid(clientId) {
      this.$store.commit('Users/usersData', '')
      this.$swal({
        title: 'Please, wait...',
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      await this.$http.get(`admin/panel/selfpay/${clientId}/info`).then((response) => {
        this.dataSelfpaids = response.data.data;
        console.log(this.dataSelfpaids)
        this.changeFormatDate(this.dataSelfpaids)

        this.$store.commit('Users/usersData', this.dataSelfpaids)
        this.$swal.close();
      }).catch((error) => {
        console.log(error)
      })
    },
    changeFormatDate(element) {
      if (element.birthday) {
        if (element.birthday.length == 10) {
          const [year, month, day] = element.birthday.split('-');
          element.birthday = `${month}-${day}-${year}`;
        }
      }
    },
    async updateDefaultPaymentMethod(paymentId) {

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

      let formData = new FormData();
      formData.append("id", this.dataSelfpaids.id);
      formData.append("paymentId", paymentId);

      await this.$http
        .post("admin/panel/set_defaulf_payment_selfpay", formData)
        .then((response) => {
          this.$swal().close();
          for (let index = 0; index < this.dataSelfpaids.payment_methods.length; index++) {
            let element = this.dataSelfpaids.payment_methods[index];
            element.is_default = 0;
          }

          let data = this.dataSelfpaids.payment_methods.find(x => x.stripe_payment_method_id == paymentId);
          if (data) {
            data.is_default = 1;
          }

        }).catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })

    },
    updatePaymentMethod(paymentId) {
      let data = this.dataSelfpaids.payment_methods.find(x => x.stripe_payment_method_id == 0);
      if (data) {
        data.stripe_payment_method_id = paymentId;
      }
    },
    addEmptyPaymentMethod() {
      let data = JSON.parse('{ "stripe_payment_method_id": 0, "is_default": 0 }');
      this.dataSelfpaids.payment_methods.push(data);

    },
    deletePaymentMethod(paymentId) {
      this.dataSelfpaids.payment_methods = this.dataSelfpaids.payment_methods.filter(x => x.stripe_payment_method_id != paymentId)
      this.renderCompont = false;
      this.$nextTick().then(() => {
        this.renderCompont = true;
      });
    },
    setDefault(paymentMethodId) {
      let data = this.dataSelfpaids.payment_methods.find(x => x.PaymentMethodId == paymentMethodId);
      data.is_default = 1;
    },
    async loadData() {
      this.clientId = this.$route.params.client_id;
      await this.getInfoSelfpaid(this.clientId);
      this.addEmptyPaymentMethod();
    }
  },

  mounted() {
    this.loadData();
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.clientId = this.$route.params.client_id;
        this.getInfoSelfpaid(this.clientId)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
}
</script>

<style scoped></style>